import { router } from 'expo-router';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { FlatList, ListRenderItemInfo, RefreshControl, View } from 'react-native';

import {
  Carousel,
  CarouselPaging,
  CarouselRef,
  ErrorCard,
  GenericCard,
  IconOffer,
  IconRefresh,
  MqSwitch,
  PaginationDots,
  ScrollView,
  createMqStyles,
  tokens,
  useMqSelect,
} from '@fhs/ui';
import { IconProfile } from '@fhs/ui/src/components/icon/svgs/profile';
import { useAuthContext } from '@fhs-legacy/frontend/src/state/auth';
import { routes } from '@fhs-legacy/frontend/src/utils/routing';

import {
  OfferCard,
  OfferCardProps,
  OfferCardSkeleton,
  PromoCode,
  RewardCard,
  RewardCardProps,
  RewardCardSkeleton,
  SeeMoreCard,
} from '../../components';
import { useLoyaltyLegacyStates } from '../../hooks/use-loyalty-legacy-states';
import { useLoyaltyIncentives } from '../../queries/loyalty.queries';
import { formatOfferCards } from '../../utils';

import { OffersDisclaimer } from './offers-disclaimer';
import { OffersSectionHeader } from './offers-section-header';
import { RewardsSectionHeader } from './rewards-section-header';

function renderOffer({ item }: ListRenderItemInfo<OfferCardProps>) {
  return <OfferCard {...item} />;
}

function renderReward({ item }: ListRenderItemInfo<RewardCardProps>) {
  return <RewardCard {...item} />;
}

export function OffersAndRewardsScreen() {
  const { isAuthenticated } = useAuthContext();
  const mqStyles = useMqStyles();
  const { formatMessage } = useIntl();
  const cardsToRender = useMqSelect({ $ltDesktop: 5 }, Infinity);
  const [carouselPaging, setCarouselPaging] = useState<CarouselPaging>();
  const carouselRef = useRef<CarouselRef>(null);

  const { loyaltyUserReady } = useLoyaltyLegacyStates();
  const {
    data,
    isError,
    isLoading: isLoyaltyIncentivesLoading,
    isFetching,
    refetch,
  } = useLoyaltyIncentives();

  const isLoading = isLoyaltyIncentivesLoading || !loyaltyUserReady;

  const offerCardProps = useMemo(
    () => formatOfferCards(data?.offers, cardsToRender),
    [data?.offers, cardsToRender]
  );

  const rewardCardProps = useMemo(
    () =>
      data?.rewards?.reduce<RewardCardProps[]>((rewardCards, reward) => {
        if (rewardCards.length >= cardsToRender || !reward) {
          return rewardCards;
        }

        if (reward.image && typeof reward.points === 'number') {
          rewardCards.push({
            points: reward.points,
            imageUrl: reward.image,
            title: reward.name,
            onPress: () => router.navigate(`${routes.loyaltyRewardsList}/${reward.id}`),
            isDisabled: !reward.isAvailable,
            buttonLabel: formatMessage({ id: 'redeemItem' }),
          });
        }

        return rewardCards;
      }, []) ?? [],
    [data?.rewards, formatMessage, cardsToRender]
  );

  const nextCarouselPage = useCallback(() => {
    carouselRef.current?.nextPage();
  }, []);

  const prevCarouselPage = useCallback(() => {
    carouselRef.current?.prevPage();
  }, []);

  const goToPage = useCallback((page: number) => {
    carouselRef.current?.goToPage(page);
  }, []);

  const onPressSignUp = useCallback(() => {
    router.navigate(routes.signIn);
  }, []);

  const seeAllOffers = useCallback(() => {
    router.navigate('v2/offers/all');
  }, []);

  const seeAllRewards = useCallback(() => {
    router.navigate(routes.rewards);
  }, []);

  const renderOffersEmpty = useCallback(() => {
    if (isLoading) {
      return (
        <ScrollView
          horizontal
          style={mqStyles.emptyList}
          contentContainerStyle={mqStyles.emptyListContent}
          showsHorizontalScrollIndicator={false}
        >
          <OfferCardSkeleton />
          <OfferCardSkeleton />
          <OfferCardSkeleton />
          <OfferCardSkeleton />
          <MqSwitch $ltDesktop={() => <OfferCardSkeleton />} />
        </ScrollView>
      );
    }

    return (
      <GenericCard
        title={formatMessage({ id: 'noOffersAvailable' })}
        description={formatMessage({ id: 'noOffersAvailableMessage' })}
        hideButton
        icon={<IconOffer size={68} color={tokens.colors.$blackOpacity10} />}
        style={mqStyles.noOffersAvailable}
      />
    );
  }, [
    isLoading,
    mqStyles.emptyList,
    mqStyles.emptyListContent,
    mqStyles.noOffersAvailable,
    formatMessage,
  ]);

  const renderRewardsEmpty = useCallback(() => {
    if (isLoading) {
      return (
        <ScrollView
          horizontal
          style={mqStyles.emptyList}
          contentContainerStyle={mqStyles.emptyListContent}
          showsHorizontalScrollIndicator={false}
        >
          <RewardCardSkeleton />
          <RewardCardSkeleton />
          <RewardCardSkeleton />
          <RewardCardSkeleton />
          <RewardCardSkeleton />
        </ScrollView>
      );
    }
    return null;
  }, [isLoading, mqStyles.emptyList, mqStyles.emptyListContent]);

  const renderOffersFooter = useCallback(() => {
    if (isLoading || offerCardProps.length === 0) {
      return null;
    }
    return (
      <SeeMoreCard
        style={mqStyles.seeMoreOffers}
        title={formatMessage(
          { id: 'lookingForMore' },
          { incentive: formatMessage({ id: 'offers' }) }
        )}
        buttonLabel={formatMessage({ id: 'seeAll' })}
        onPress={seeAllOffers}
      />
    );
  }, [formatMessage, mqStyles.seeMoreOffers, isLoading, seeAllOffers, offerCardProps.length]);

  const renderRewardsFooter = useCallback(() => {
    if (isLoading || rewardCardProps.length === 0) {
      return null;
    }
    return (
      <SeeMoreCard
        style={mqStyles.seeMoreRewards}
        title={formatMessage(
          { id: 'lookingForMore' },
          { incentive: formatMessage({ id: 'rewards' }) }
        )}
        buttonLabel={formatMessage({ id: 'seeAll' })}
        onPress={seeAllRewards}
      />
    );
  }, [formatMessage, mqStyles.seeMoreRewards, isLoading, seeAllRewards, rewardCardProps.length]);

  const renderHeader = useCallback(() => {
    return <View style={mqStyles.listHeader} />;
  }, [mqStyles.listHeader]);

  const listHeader = useMqSelect({ $ltDesktop: renderHeader }, undefined);

  const offerCardWidth = useMqSelect({ $gteDesktop: 215 }, 160);

  if (isError) {
    return (
      <ErrorCard
        description={formatMessage({ id: 'offersLoadingErrorMessage' })}
        buttonType="outline"
        buttonIcon={<IconRefresh color={tokens.colors.$houseRedDarken} />}
        buttonTitle="Reload"
        icon={<IconOffer size={68} color={tokens.colors.$blackOpacity10} />}
        buttonHandle={refetch}
      />
    );
  }

  return (
    <ScrollView
      style={mqStyles.page}
      refreshControl={<RefreshControl refreshing={isFetching} onRefresh={refetch} />}
    >
      <View style={mqStyles.content}>
        <View style={mqStyles.contentPadding}>
          <OffersSectionHeader
            nextCarouselPage={nextCarouselPage}
            prevCarouselPage={prevCarouselPage}
            onPressSeeAll={seeAllOffers}
          />
          <MqSwitch $ltDesktop={() => <PromoCode />} />
        </View>
        <MqSwitch
          $base={() => (
            <FlatList
              centerContent
              style={mqStyles.flatList}
              contentContainerStyle={mqStyles.offersContentFlatList}
              horizontal
              data={offerCardProps}
              renderItem={renderOffer}
              ListEmptyComponent={renderOffersEmpty}
              showsHorizontalScrollIndicator={false}
              ListFooterComponent={renderOffersFooter}
              ListHeaderComponent={listHeader}
            />
          )}
          $gteDesktop={() => (
            <>
              {offerCardProps.length === 0 ? (
                renderOffersEmpty()
              ) : (
                <View style={mqStyles.offersCarousel}>
                  <Carousel
                    centerContent
                    ref={carouselRef}
                    data={offerCardProps}
                    renderItem={renderOffer}
                    itemWidth={offerCardWidth}
                    onChangePage={setCarouselPaging}
                  />
                  {carouselPaging && (
                    <PaginationDots
                      style={mqStyles.carouselPagination}
                      selectedPage={carouselPaging.currentPage}
                      totalPages={carouselPaging.totalPages}
                      onPressPage={goToPage}
                    />
                  )}
                </View>
              )}
            </>
          )}
        />
        <View style={mqStyles.contentPadding}>
          <OffersDisclaimer />
          <RewardsSectionHeader onPressSeeAll={seeAllRewards} />
        </View>
        {isAuthenticated ? (
          <FlatList
            centerContent
            style={mqStyles.flatList}
            contentContainerStyle={mqStyles.rewardsContentFlatList}
            horizontal
            data={rewardCardProps}
            renderItem={renderReward}
            ListEmptyComponent={renderRewardsEmpty}
            showsHorizontalScrollIndicator={false}
            ListFooterComponent={renderRewardsFooter}
            ListHeaderComponent={listHeader}
          />
        ) : (
          <View style={mqStyles.unauthenticatedContainer}>
            <GenericCard
              icon={<IconProfile fill={tokens.colors.$blackOpacity10} />}
              title={formatMessage({ id: 'signUpToGetFreeRewards' })}
              description={formatMessage({ id: 'earnPointsEnjoyExclusiveOffersAndRewards' })}
              buttonTitle={formatMessage({ id: 'signUpNow' })}
              buttonHandle={onPressSignUp}
            />
          </View>
        )}
        <View style={mqStyles.footer} />
      </View>
    </ScrollView>
  );
}

const useMqStyles = createMqStyles({
  page: {
    $base: {
      flex: 1,
      width: '100%',
      backgroundColor: tokens.colors.$white,
    },
  },
  content: {
    $base: {
      flex: 1,
    },
    $gteDesktop: {
      width: 908,
      alignItems: 'center',
      alignSelf: 'center',
    },
  },
  contentPadding: {
    $base: {
      paddingHorizontal: 16,
      width: '100%',
    },
    $gteDesktop: {
      paddingHorizontal: 0,
    },
  },
  errorContainer: {
    $base: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      padding: 12,
    },
  },
  flatList: {
    $base: {
      width: '100%',
      marginTop: 16,
      flexGrow: 0,
    },
  },
  offersCarousel: {
    $base: {
      marginTop: 16,
      width: '100%',
    },
  },
  carouselPagination: {
    $base: {
      alignSelf: 'center',
      marginTop: 16,
    },
  },
  offersContentFlatList: {
    $base: {
      gap: 16,
    },
    $ltDesktop: {
      gap: 12,
    },
  },
  rewardsContentFlatList: {
    $base: {
      gap: 12,
      alignItems: 'flex-end',
      flexGrow: 0,
    },
  },
  seeMoreOffers: {
    $base: {
      height: 297,
      width: 215,
    },
    $ltDesktop: {
      width: 160,
      height: 232,
      marginRight: 16,
    },
  },
  seeMoreRewards: {
    $base: {
      height: 175,
      width: 215,
    },
    $ltDesktop: {
      height: 174,
      width: 160,
      marginRight: 16,
    },
  },
  unauthenticatedContainer: {
    $base: {
      borderRadius: 8,
      borderWidth: 1,
      borderColor: tokens.colors.$blackOpacity10,
      marginTop: 12,
      paddingTop: 16,
      paddingHorizontal: 16,
      marginHorizontal: 16,
    },
    $gteDesktop: {
      width: '100%',
    },
  },
  listHeader: {
    $ltDesktop: {
      width: 4,
    },
  },
  emptyList: {
    $base: {
      flexGrow: 0,
    },
  },
  emptyListContent: {
    $base: {
      gap: 12,
    },
    $gteDesktop: {
      width: 908,
      marginTop: 16,
    },
  },
  noOffersAvailable: {
    $base: {
      maxWidth: 300,
      paddingVertical: 8,
    },
    $gteDesktop: {
      maxWidth: 600,
    },
  },
  footer: {
    $base: {
      marginBottom: 80,
    },
  },
});
