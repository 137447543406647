import { Image } from 'expo-image';
import { router } from 'expo-router';
import cloneDeep from 'lodash/cloneDeep';
import { useCallback, useMemo } from 'react';
import { FlatList, ListRenderItemInfo, Platform, StyleSheet, View } from 'react-native';
import { v4 as uuidv4 } from 'uuid';

import { ActionSheet, Button, IconCartFill, Text, tokens, useMqSelect } from '@fhs/ui';
import { IIncentiveSelection } from '@fhs-legacy/frontend/src/generated/graphql-gateway';
import { routes } from '@fhs-legacy/frontend/src/utils/routing';

import { useAddIncentiveToCart } from '../../hooks/use-add-incentive-to-cart';
import { useOrderLegacyStates } from '../../hooks/use-legacy-order-states';
import { useOfferCart } from '../../state/offer-guide-cart-state';

import { SummaryStepItem, SummaryStepItemProps } from './summary-step-item';

const isWeb = Platform.OS === 'web';

const renderItem = ({ item }: ListRenderItemInfo<SummaryStepItemProps>) => {
  return <SummaryStepItem {...item} />;
};

const renderStepSeparator = () => {
  return <View style={styles.stepSeparator} />;
};

export function Summary() {
  const { steps: offerSteps, offerInfo, goToStep, setSelectedEligibleItem } = useOfferCart();
  const { image, description, termsAndConditions, name } = offerInfo ?? {};
  const buttonMargin = useMqSelect({ $gteDesktop: { margin: 28 } }, { marginBottom: 0 });
  const { addIncentiveToCart } = useAddIncentiveToCart();
  const { cartEntries, addItemToCart, removeFromCart, updateQuantity } = useOrderLegacyStates();

  const summaryStepItemProps = useMemo(
    () =>
      offerSteps.map<SummaryStepItemProps>((offerStep, index) => ({
        ...offerStep,
        onPressEdit: () => {
          router.setParams({
            isEdit: 'true',
          });

          const selectedEligibleItem = offerStep.eligibleItems.find(
            eligibleItem => offerStep?.selectedEntry?._id === eligibleItem.id
          );
          setSelectedEligibleItem(selectedEligibleItem);
          goToStep(index);
          setTimeout(() => {
            router.setParams({
              isEdit: undefined,
            });
          }, 100);
        },
      })),
    [offerSteps, goToStep, setSelectedEligibleItem]
  );

  const onPressAddToCart = useCallback(() => {
    if (offerInfo) {
      const selections: IIncentiveSelection[] = [];
      const cartEntriesClone = cloneDeep(cartEntries);
      offerSteps.forEach(step => {
        const itemInCart = cartEntriesClone.find(
          cartEntry => cartEntry.cartId === step.selectedEntry?.cartId
        );

        if (itemInCart) {
          const itemClone = cloneDeep(itemInCart);
          itemClone.quantity = 1;
          itemClone.cartId = uuidv4();
          addItemToCart(itemClone, undefined, false);

          selections.push({
            incentiveId: offerInfo.id,
            key: step.key,
            lineId: itemClone?.cartId ?? '',
            quantity: 1,
            rank: step.rank,
          });

          itemInCart.quantity--;

          if (itemInCart.quantity <= 0) {
            removeFromCart({ cartId: itemInCart.cartId });
          } else {
            updateQuantity(itemInCart.cartId, itemInCart.quantity);
          }

          return;
        }

        selections.push({
          incentiveId: offerInfo.id,
          key: step.key,
          lineId: step.selectedEntry?.cartId ?? '',
          quantity: 1,
          rank: step.rank,
        });
        addItemToCart(step.selectedEntry, undefined, false);
      });

      addIncentiveToCart(offerInfo, selections, true);
      router.dismiss();
      setTimeout(() => {
        if (isWeb) {
          router.replace(routes.menu);
        } else {
          router.navigate(routes.menu);
        }
      }, 100);
    }
  }, [
    offerInfo,
    offerSteps,
    addItemToCart,
    addIncentiveToCart,
    cartEntries,
    removeFromCart,
    updateQuantity,
  ]);

  return (
    <View style={{ flex: 1 }}>
      <View style={styles.imageContainer}>
        <Image source={{ uri: image }} contentFit="contain" style={{ height: 216 }} />
      </View>
      <ActionSheet.ScrollView>
        <View style={styles.content}>
          <Text.Heading type="one" style={{ textAlign: 'center' }}>
            {name}
          </Text.Heading>
          {description && (
            <Text.Ui size="md" style={styles.subtitle}>
              {description}
            </Text.Ui>
          )}
          <View style={styles.stepsContainer}>
            <FlatList
              data={summaryStepItemProps}
              renderItem={renderItem}
              ItemSeparatorComponent={renderStepSeparator}
            />
          </View>
          <View style={styles.separator} />
          <Text.Paragraph size="sm" style={styles.termAndCondition}>
            {termsAndConditions}
          </Text.Paragraph>
        </View>
      </ActionSheet.ScrollView>
      <View style={styles.buttonContainer}>
        <Button size="xl" style={[styles.button, { ...buttonMargin }]} onPress={onPressAddToCart}>
          <Button.Icon>
            <IconCartFill />
          </Button.Icon>
          <Button.Text>Add Offer To Cart</Button.Text>
        </Button>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  imageContainer: {
    borderWidth: 1,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderColor: tokens.colors.$blackOpacity04,
  },
  content: {
    marginVertical: 24,
    paddingHorizontal: 16,
  },
  subtitle: {
    textAlign: 'center',
    marginTop: 4,
  },
  stepsContainer: {
    marginTop: 20,
    borderWidth: 1,
    borderColor: tokens.colors.$blackOpacity10,
    borderRadius: 8,
  },
  stepSeparator: {
    height: 1,
    backgroundColor: tokens.colors.$blackOpacity04,
  },
  separator: {
    marginTop: 20,
    width: '100%',
    height: 1,
    backgroundColor: tokens.colors.$blackOpacity04,
  },
  termAndCondition: {
    color: tokens.colors.$blackOpacity55,
    marginTop: 20,
  },
  buttonContainer: {
    borderTopWidth: 1,
    borderTopColor: tokens.colors.$blackOpacity10,
  },
  button: {
    margin: 16,
  },
});
