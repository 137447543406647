import { OfferIncentiveType } from '@fhs/backend/amplify/functions/_temp/graphql/API';
import { ISanityLocaleBlockContent, ServiceMode } from '@rbi-ctg/menu';

export type OfferMock = {
  name: string;
  id: string;
  description: string;
  expiration: string;
  imageUrl: string;
  type: string; // TODO: Type this properly
};

export interface Offer {
  id: string;
  image: string;
  name: string;
  expiryDate: string;
  isAvailable: boolean;
}

export interface Reward {
  id: string;
  image: string;
  name: string;
  point: number;
  isAvailable: boolean;
}

export interface LoyaltyIncentives {
  offers: Offer[];
  rewards: Reward[];
}

export interface GetLoyaltyIncentivesParams {
  loyaltyId?: string;
  storeId: string;
  serviceMode: ServiceMode;
}

// Offer V2

export enum PricingStrategyEnum {
  DISCOUNT = 'DISCOUNT',
  FIXED_PRICE = 'FIXED_PRICE',
  TIER_PRICE = 'TIER_PRICE',
}

export enum DiscountIncentiveTypeEnum {
  ITEM_LEVEL_DISCOUNT = 'ITEM_LEVEL_DISCOUNT',
  GLOBAL_DISCOUNT = 'GLOBAL_DISCOUNT',
  BOGO_DISCOUNT = 'BOGO_DISCOUNT',
  BOGO_DYNAMIC_DISCOUNT = 'BOGO_DYNAMIC_DISCOUNT',
  BUNDLE_DISCOUNT = 'BUNDLE_DISCOUNT',
  FEES_DISCOUNT = 'FEES_DISCOUNT',
}

export enum FixedPriceIncentiveTypeEnum {
  PRICE_POINT_DISCOUNT = 'PRICE_POINT_DISCOUNT',
  STEPPER_DISCOUNT = 'STEPPER_DISCOUNT',
}

export enum TierIncentiveTypeEnum {
  TIER_PRICE = 'TIER_PRICE',
}

export type IncentiveType =
  | DiscountIncentiveTypeEnum
  | FixedPriceIncentiveTypeEnum
  | TierIncentiveTypeEnum;

export interface LoyaltyOfferV2Incentive {
  defaultPrice?: number;
  maxItemQuantityDiscount?: number;
  maxPriceToDiscount?: number;
  pricingStrategy?: string; //pricingStrategyEnum
  type?: string;
  value?: number;
}

export interface LoyaltyCmsOfferV2Incentive {
  discountType: string;
  discountValue: number;
  incentiveType: OfferIncentiveType;
  discountFees?: (string | null)[] | null | undefined;
  _type: string;
}

export interface LoyaltyOfferV2 {
  cardDescription?: string;
  cmsId: string;
  description: string;
  expiryDate?: string;
  id: string;
  image?: string;
  incentives?: LoyaltyOfferV2Incentive;
  isAvailable?: boolean;
  name: string;
  termsAndConditions?: string;
}

export interface LoyaltyCmsOfferV2 {
  __typename: string;
  _type: string;
  _id: string;
  id: string;
  name: ISanityLocaleBlockContent;
  description: ISanityLocaleBlockContent;
  loyaltyEngineId: string;
  incentives: LoyaltyCmsOfferV2Incentive[];
}

export enum IncentiveEvaluationErrorCodes {
  UNSATISFIED_CART_REQUIREMENTS = 'unsatisfied-cart-requirements',
  WITHIN_COOL_DOWN_PERIOD = 'within-cool-down-period',
  EXCEEDS_LIMIT_PER_ORDER = 'exceeds-limit-per-order',
  INVALID_SERVICE_MODE = 'invalid-service-mode',
  INVALID_STORE_ID = 'invalid-store-id',
  NOT_FIRST_ORDER = 'not-first-order',
  NOT_USER_BIRTHDAY = 'not-user-birthday',
  INVALID_END_DATE = 'invalid-end-date',
  INVALID_START_DATE = 'invalid-start-date',
  INVALID_DAY_OF_THE_WEEK = 'invalid-day-of-the-week',
  INVALID_STACKING = 'invalid-stacking',
  EXCEEDS_MAX_REDEMPTION = 'exceeds-max-redemption',
  BELOW_MINIMUM_SPEND = 'below-minimum-spend',
  INVALID_PAYMENT_METHOD = 'invalid-payment-method',
  INSUFFICIENT_POINT_BALANCE = 'insufficient-point-balance',
  INVALID_SWAPS = 'invalid-swaps',
  INVALID_TIME_SPAN = 'invalid-time-span',
  USER_ENROLLMENT = 'user-enrollment',
  OUT_OF_DAY_PART = 'out-of-day-part',
  NOT_AVAILABLE_IN_STORE = 'not-available-in-store',
  OFFER_NOT_AVAILABLE = 'offer-not-available',
}
