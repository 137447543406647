import { FlashList, type FlashListProps, type ViewToken } from '@shopify/flash-list';
import { type ReactElement, memo, useCallback, useMemo, useState } from 'react';
import {
  Dimensions,
  type GestureResponderEvent,
  type NativeScrollEvent,
  type NativeSyntheticEvent,
  StyleSheet,
  View,
} from 'react-native';

import { useBoxRef } from '@fhs/utils';

import { createMqStyles, useMqSelect } from '../../mq-styles';
import { tokens } from '../../tokens';
import { IconArrowUp } from '../icon';
import { Pressable } from '../pressable';

import { useAnchorSectionList } from './context';
import { SectionListHeader } from './section-list-header';
import { SectionListItem } from './section-list-item';
import type { FlashListDatum, ItemType, SectionListProps, TileSizeType } from './types';
import { useDebouncedCallback } from './use-debounced-callback';
import { useInitialAutoScroll } from './use-initial-auto-scroll';

type ExtraDataType = { numColumns: number; tileSize: TileSizeType };

const MemoedSectionListItem = memo(
  ({
    item,
    tileSize,
    isFirstColumn,
    isLastColumn,
  }: {
    item: ItemType;
    tileSize: TileSizeType;
    isFirstColumn: boolean;
    isLastColumn: boolean;
  }) => {
    const itemStyles = useItemStyles();
    return (
      <SectionListItem
        item={item}
        tileSize={tileSize}
        style={[
          itemStyles.item,
          isFirstColumn && itemStyles.firstColumn,
          isLastColumn && itemStyles.lastColumn,
        ]}
      />
    );
  }
);

const renderItem = ({
  item: datum,
  extraData = { numColumns: 2, tileSize: 'lg' },
}: {
  item: FlashListDatum;
  extraData?: ExtraDataType;
}) => {
  switch (datum.type) {
    case 'item': {
      const numColumns = extraData.numColumns;
      const indexWithinSection = datum.indexWithinSection;

      return (
        <MemoedSectionListItem
          item={datum.item}
          tileSize={extraData.tileSize}
          isFirstColumn={indexWithinSection % numColumns === 0}
          isLastColumn={indexWithinSection % numColumns === numColumns - 1}
        />
      );
    }

    case 'section': {
      return <SectionListHeader title={datum.section.title} />;
    }

    case 'custom': {
      return datum.section.items as ReactElement;
    }

    default: {
      return null;
    }
  }
};

const overrideItemLayout: FlashListProps<FlashListDatum>['overrideItemLayout'] = (
  layout,
  item,
  _index,
  maxColumns
) => {
  switch (item.type) {
    case 'section':
    case 'custom': {
      // you have to mutate the layout
      layout.span = maxColumns;
      break;
    }
  }
};

const getItemType: FlashListProps<FlashListDatum>['getItemType'] = item => item.type;

const VIEWABILITY_CONFIG: FlashListProps<FlashListDatum>['viewabilityConfig'] = {
  itemVisiblePercentThreshold: 50,
};

const MemoedFlashList = memo(
  ({
    footer,
    footerStyles,
    extraData,
    contentContainerStyle,
    numColumns,
    handleScroll,
  }: {
    extraData: ExtraDataType;
    footer?: ReactElement;
    footerStyles?: FlashListProps<FlashListDatum>['ListFooterComponentStyle'];
    contentContainerStyle: FlashListProps<FlashListDatum>['contentContainerStyle'];
    numColumns: FlashListProps<FlashListDatum>['numColumns'];
    handleScroll: FlashListProps<FlashListDatum>['onScroll'];
  }) => {
    const { setActiveSectionId, flashListData, flashListRef } = useAnchorSectionList();

    const handleViewableItemsChanged: FlashListProps<FlashListDatum>['onViewableItemsChanged'] =
      useCallback(
        ({ viewableItems }) => {
          let firstVisibleItem: ViewToken | null = null;

          for (const viewableItem of viewableItems) {
            switch (viewableItem.item.type) {
              case 'section':
              case 'custom': {
                setActiveSectionId(viewableItem.item?.section?.id);
                return;
              }
              case 'item': {
                // Save first visible item in case after iterating all viewableItems we didn't find any section or 'custom' types
                if (!firstVisibleItem) {
                  firstVisibleItem = viewableItem;
                }
              }
            }
          }

          // If we got to this point we have iterated over all viewable items and found no 'section' or 'custom' types
          // If we have at least 1 item, set the active to the section of that item.
          if (firstVisibleItem) {
            setActiveSectionId(firstVisibleItem.item?.parentSectionId);
          }
        },
        [setActiveSectionId]
      );

    return (
      <FlashList<FlashListDatum>
        testID="section-list-vertical"
        data={flashListData}
        ref={flashListRef}
        horizontal={false}
        contentContainerStyle={contentContainerStyle}
        estimatedItemSize={200}
        drawDistance={2000}
        renderItem={renderItem}
        numColumns={numColumns}
        extraData={extraData}
        overrideItemLayout={overrideItemLayout}
        getItemType={getItemType}
        viewabilityConfig={VIEWABILITY_CONFIG}
        onViewableItemsChanged={handleViewableItemsChanged}
        onScroll={handleScroll}
        ListFooterComponent={footer}
        ListFooterComponentStyle={footerStyles}
      />
    );
  }
);

export function SectionList(props: SectionListProps) {
  const context = useAnchorSectionList();

  // Actual column width can potentially be smaller than targetMinimumColumnWidth in the event
  // we force 2 columns when there is less than targetMinimumColumnWidth * 2 available space
  const targetMinimumColumnWidth = useMqSelect({ $gteDesktop: 220 }, 200);
  const horizontalPadding = useMqSelect({ $gteDesktop: 32 }, 16);
  const verticalPadding = useMqSelect({ $gteDesktop: 28 }, 20);

  const [availableWidth, setAvailableWidth] = useState(() => Dimensions.get('window').width);
  const [scrolledPastFold, setScrolledPastFold] = useState(false);

  const numColumns = Math.max(
    Math.floor((availableWidth - horizontalPadding * 2) / targetMinimumColumnWidth),
    2
  );

  const handleScrollForScrollEnd = useDebouncedCallback(
    (_event: NativeSyntheticEvent<NativeScrollEvent>) => {
      // Debouncing this callback is our make-shift way of reimplementing a "scrollend" event
      if (context.manualActiveSectionId) {
        // Scroll finished, clear manual active section id so scrolling triggers
        // the active section id to be set based on visibility again.
        context.setActiveSectionId(context.manualActiveSectionId);
        context.setManualActiveSectionId('');
      }
    },
    250
  );

  const handleScrollRef = useBoxRef((event: NativeSyntheticEvent<NativeScrollEvent>) => {
    const FOLD_HEIGHT = 400;
    const nowScrolledPastFold = event.nativeEvent.contentOffset.y > FOLD_HEIGHT;
    if (scrolledPastFold !== nowScrolledPastFold) {
      setScrolledPastFold(nowScrolledPastFold);
    }

    handleScrollForScrollEnd(event);
  });

  const { scrollDone, triggerInitialAutoScroll, saveLastScrollOffset } = useInitialAutoScroll();

  const handleScroll = useCallback(
    (event: NativeSyntheticEvent<NativeScrollEvent>) => {
      handleScrollRef.current(event);
      saveLastScrollOffset(event.nativeEvent.contentOffset.y);
    },
    [handleScrollRef, saveLastScrollOffset]
  );

  return (
    <View
      style={[wrapperStyles.wrapper, { opacity: scrollDone ? 1 : 0 }]}
      onLayout={useCallback(
        event => {
          const { width } = event.nativeEvent.layout;
          triggerInitialAutoScroll();
          if (width) {
            setAvailableWidth(width);
          }
        },
        [triggerInitialAutoScroll]
      )}
    >
      <MemoedFlashList
        contentContainerStyle={useMemo(
          () => ({
            backgroundColor: tokens.colors.$white,
            paddingHorizontal: horizontalPadding,
            paddingVertical: verticalPadding,
          }),
          [horizontalPadding, verticalPadding]
        )}
        footerStyles={useMemo(
          () => ({
            // Negate content container styles
            marginHorizontal: -horizontalPadding,
            marginVerticalTop: verticalPadding,
          }),
          [horizontalPadding, verticalPadding]
        )}
        extraData={useMemo(
          () => ({ numColumns, tileSize: context.size }),
          [numColumns, context.size]
        )}
        numColumns={numColumns}
        handleScroll={handleScroll}
        {...props}
      />
      <ScrollToTopButton
        isVisible={scrolledPastFold}
        onPress={useCallback(
          () => context.flashListRef.current?.scrollToOffset({ offset: 0, animated: true }),
          [context.flashListRef]
        )}
      />
    </View>
  );
}

const wrapperStyles = StyleSheet.create({
  wrapper: {
    overflow: 'hidden',
    flexGrow: 1,
    flexShrink: 9999,
  },
});

const useItemStyles = createMqStyles({
  item: {
    $base: {
      paddingLeft: 6,
      paddingRight: 6,
      paddingBottom: 10,
      paddingTop: 10,
    },
    $gteDesktop: {
      paddingLeft: 6,
      paddingRight: 6,
      paddingBottom: 12,
      paddingTop: 12,
    },
  },
  firstColumn: {
    $base: {
      paddingLeft: 0,
    },
  },
  lastColumn: {
    $base: {
      paddingRight: 0,
    },
  },
});

const ScrollToTopButton = (props: {
  isVisible: boolean;
  onPress: (event: GestureResponderEvent) => void;
}) => {
  return (
    <Pressable
      onPress={props.onPress}
      borderRadius={1000}
      style={scrollToTopButtonStyles.button}
      disabled={!props.isVisible}
      disabledStyle={scrollToTopButtonStyles.isNotVisible}
    >
      <IconArrowUp color={tokens.colors.$houseDark} size={32} title="Scroll to top" />
    </Pressable>
  );
};

const scrollToTopButtonStyles = StyleSheet.create({
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    right: 16,
    bottom: 16,
    position: 'absolute',
    backgroundColor: tokens.colors.$white,
    height: 56,
    width: 56,
    borderWidth: 1,
    borderColor: tokens.colors.$blackOpacity10,
    opacity: 1,
  },
  isNotVisible: {
    opacity: 0,
    pointerEvents: 'none',
  },
});
