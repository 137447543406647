import { Image } from 'expo-image';
import { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';

import { Button, IconShoppingBag, ItemStepper, Text, YStack, tokens } from '@fhs/ui';

import { useOrderLegacyStates } from '../../hooks/use-legacy-order-states';
import { useOfferCart } from '../../state/offer-guide-cart-state';

export function ItemInCart() {
  const { offerInfo, steps: offerSteps, goFromScratch, applyPreSelectedItems } = useOfferCart();
  const { image } = offerInfo ?? {};
  const { cartEntries } = useOrderLegacyStates();
  const steps = useMemo(
    () =>
      offerSteps.map(step => {
        const selectedItem = cartEntries?.find(
          cartEntry => cartEntry.cartId === step.preSelectedItem
        );
        const eligibleItemImage = step.eligibleItems.find(
          eligibleItem => eligibleItem.id === selectedItem?._id
        )?.image?.asset?.uri;

        return {
          isActive: !selectedItem,
          image: eligibleItemImage,
        };
      }),
    [cartEntries, offerSteps]
  );

  return (
    <View style={{ flex: 1 }}>
      <View>
        <Image source={{ uri: image }} contentFit="contain" style={{ height: 216 }} />
      </View>
      <View style={styles.content}>
        <View style={styles.shoppingBagContainer}>
          <IconShoppingBag size={40} />
        </View>
        <Text.Heading type="one" style={{ textAlign: 'center' }}>
          It seems like you have some items in your cart
        </Text.Heading>
        <View style={styles.stepContainer}>
          <Text.Ui size="sm">Items are already in cart</Text.Ui>
          <ItemStepper steps={steps} />
        </View>
      </View>
      <YStack style={styles.buttonContainer}>
        <Button size="xl" onPress={() => applyPreSelectedItems(cartEntries)}>
          <Button.Text>Use These Items</Button.Text>
        </Button>
        <Button type="outline" size="xl" onPress={goFromScratch}>
          <Button.Text>Select From Scratch</Button.Text>
        </Button>
      </YStack>
    </View>
  );
}

const styles = StyleSheet.create({
  content: {
    marginVertical: 24,
    paddingHorizontal: 16,
    alignItems: 'center',
    gap: 16,
  },
  shoppingBagContainer: {
    backgroundColor: tokens.colors.$blackOpacity04,
    borderRadius: 200,
    width: 64,
    height: 64,
    alignItems: 'center',
    justifyContent: 'center',
  },
  stepContainer: {
    width: '100%',
    borderWidth: 1,
    borderColor: tokens.colors.$blackOpacity04,
    padding: 16,
    borderRadius: 8,
    gap: 16,
    alignItems: 'center',
  },
  buttonContainer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    gap: 12,
    paddingHorizontal: 16,
    paddingBottom: 21,
  },
});
